import { faEnvelope, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from "../../config/config";
import { Button } from "../../util/Styles";

export const SendMailButton = () => {
  return (
    <Button
      aria-label={"send-email-to-zeam"}
      onClick={() => {
        window.location.href = `mailto:${config.contactInfo.email}`;
      }}
    >
      <FontAwesomeIcon icon={faEnvelope} />
      {config.contactInfo.email}
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  );
};
