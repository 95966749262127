import React from "react";
import { useTranslation } from "react-i18next";
import {
  HeaderContainer,
  Burger,
  Nav,
  NavLink,
  CurrentLanguage,
  LanguageContainer,
  LanguageItem,
  LanguageList,
  HeaderWrapper,
  HeaderActionWrapper,
} from "../../util/Styles";
import { Routes } from "../../util/Routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { Logo } from "./Logo";
import { CreateNavUrl } from "../../util/Utils";
import SunIcon from "../../assets/images/icons/sun_icon.svg";
import MoonIcon from "../../assets/images/icons/moon_icon.svg";

const Header: React.FC<{ toggleTheme: () => void }> = ({ toggleTheme }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLanguageListOpen, setIsLanguageListOpen] = React.useState(false);
  const { i18n, t } = useTranslation();
  const languageContainerRef = React.useRef<HTMLDivElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const languages = [
    { code: "en", label: "GB" },
    { code: "es", label: "ES" },
    { code: "de", label: "DE" },
    { code: "fr", label: "FR" },
    { code: "jp", label: "JP" },
    { code: "tr", label: "TR" },
  ];

  const currentLanguageLabel = languages.find((lang) => lang.code === i18n.language)?.label;

  const filteredLanguages = languages.filter((lang) => lang.label !== currentLanguageLabel);

  // Handlers
  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleLanguageList = () => {
    setIsLanguageListOpen(!isLanguageListOpen);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsLanguageListOpen(false); // Close the language list after selection

    // Update the URL with the new language
    const currentPath = location.pathname;
    const currentLanguageCode = currentPath.split("/")[1]; // Assuming language is the first part of the path
    const newPath = currentPath.replace(`/${currentLanguageCode}`, `/${language.split("-")[0]}`);
    navigate(newPath); // Navigate to the new path with the updated language
  };

  // Close language list if clicked outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (languageContainerRef.current && !languageContainerRef.current.contains(event.target as Node)) {
        setIsLanguageListOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Link to={CreateNavUrl(Routes.ROOT)} style={{ textDecoration: "none" }}>
          <Logo hasTitle size={55} zoomed />
        </Link>
        <Burger onClick={toggleMenu} className={isOpen ? "open" : ""}>
          <div></div>
          <div></div>
          <div></div>
        </Burger>
        <Nav $opened={isOpen.toString()}>
          <NavLink
            to={CreateNavUrl(Routes.ROOT)}
            onClick={closeMenu}
            selected={location.pathname === CreateNavUrl(Routes.ROOT)}
          >
            {t("screen.header.home")}
          </NavLink>
          <NavLink
            to={CreateNavUrl(Routes.PORTFOLIO)}
            onClick={closeMenu}
            selected={location.pathname === CreateNavUrl(Routes.PORTFOLIO)}
          >
            {t("screen.header.portfolio")}
          </NavLink>
          <NavLink
            to={CreateNavUrl(Routes.BIO)}
            onClick={closeMenu}
            selected={location.pathname === CreateNavUrl(Routes.BIO)}
          >
            {t("screen.header.about")}
          </NavLink>
          <NavLink
            to={CreateNavUrl(Routes.CONTACT)}
            onClick={closeMenu}
            selected={location.pathname === CreateNavUrl(Routes.CONTACT)}
          >
            {t("screen.header.contact")}
          </NavLink>
          <HeaderActionWrapper>
            <LanguageContainer ref={languageContainerRef}>
              <CurrentLanguage className="cursor-pointer" onClick={toggleLanguageList}>
                <FlagIcon
                  code={currentLanguageLabel === "en" ? "GB" : (currentLanguageLabel as FlagIconCode)}
                  style={{ width: 24, height: 15, borderRadius: 5 }}
                />
              </CurrentLanguage>
              <LanguageList $isopen={isLanguageListOpen.toString()}>
                {filteredLanguages.map((lang, index) => (
                  <LanguageItem key={lang.code} onClick={() => changeLanguage(lang.code)} $delay={index * 200}>
                    <FlagIcon
                      code={lang.label === "en" ? "GB" : (lang.label as FlagIconCode)}
                      style={{ width: 24, height: 15, borderRadius: 5 }}
                    />
                  </LanguageItem>
                ))}
              </LanguageList>
            </LanguageContainer>

            <img
              className="cursor-pointer"
              onClick={toggleTheme}
              style={{ width: 24, height: 24 }}
              src={localStorage.getItem("theme") === "dark" ? SunIcon : MoonIcon}
              alt={"toggle_theme"}
            />
          </HeaderActionWrapper>
        </Nav>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
