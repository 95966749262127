import React from "react";
import { SocialLinks } from "./SocialLinks";
import { Logo } from "./Logo";
import { BackgroundImage, FooterWrapper } from "../../util/Styles";
import footerBgLight from "../../assets/images/footer-bg-light.webp";
import footerBgDark from "../../assets/images/footer-bg-dark.webp";
import { useTranslation } from "react-i18next";
import zeamLogoDark from "../../assets/images/zeam-ic-white-min.png";
import zeamLogoLight from "../../assets/images/zeam-ic-dark-min.png";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <BackgroundImage
        className="hide-xs"
        $bottom={"true"}
        src={localStorage.getItem("theme") === "dark" ? footerBgDark : footerBgLight}
        alt={"portfolio bg"}
      />
      <div
        data-aos="fade"
        data-aos-delay="100"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 30,
          paddingTop: 60,
          position: "relative",
        }}
      >
        <Logo size={100} />
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <span style={{ fontSize: 45 }}>ZEAM</span>
          <span style={{ fontFamily: "Bungee Hairline", fontWeight: 900 }}>
            The music
            <br />
            visionary
          </span>
        </div>
        <div className="responsive-flex" style={{ gap: 15 }}>
          <SocialLinks hasEmail />
        </div>
        <p style={{ fontFamily: "Montserrat", fontSize: 12 }}>
          <img
            style={{
              width: 20,
              height: 20,
              marginRight: 5,
              verticalAlign: "middle",
            }}
            src={localStorage.theme === "dark" ? zeamLogoDark : zeamLogoLight}
            alt={"zeam studio logo"}
          />
          {t("footer.copyright", { currentYear: new Date().getFullYear() })}
        </p>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
