import { config } from "../../config/config";
import { HeaderLogo } from "../../util/Styles";

export const Logo = (props: { hasTitle?: boolean; size?: number; still?: boolean; zoomed?: boolean }) => {
  return (
    <HeaderLogo size={props.size} zoomed={props.zoomed ? "true" : "false"}>
      <div style={{ position: "relative" }}>
        {props.still ? (
          <img src={config.site.logoStill} alt={config.site.name} className="header-logo" />
        ) : (
          <div className={"logo-video-container"}>
            <video
              autoPlay
              muted
              loop
              playsInline
              disablePictureInPicture
              controlsList="nodownload"
              onContextMenu={() => false}
              preload="auto"
            >
              <source src={config.site.animatedLogo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {config.decoration.logoBackground && (
          <>
            <img className="header-decoration" src={config.decoration.logoBackground} alt="header-logo-decoration" />
            <img className="header-decoration" src={config.decoration.logoBackground} alt="header-logo-decoration" />
          </>
        )}
      </div>
      {props.hasTitle && (
        <div>
          <h1>ZEAM</h1>
          <p>The music visionary</p>
        </div>
      )}
    </HeaderLogo>
  );
};
