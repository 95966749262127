import React from "react";
import { projects, Project } from "../../util/ProjectsData";
import { ProjectImage, ProjectContainer, IconButton } from "../../util/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export const ProjectPage = (props: {
  selectedProject?: string;
  setSelectedProject: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { t } = useTranslation();

  const project = projects.find((p: Project) => p.slug === props.selectedProject);

  const handleCloseProject = () => {
    props.setSelectedProject(undefined);
  };

  return project ? (
    <ProjectContainer onClick={handleCloseProject} className="projectContainer">
      <IconButton onClick={handleCloseProject} style={{ marginBottom: 20 }}>
        <FontAwesomeIcon icon={faCircleChevronLeft} />
        {t("screen.back")}
      </IconButton>

      <div>
        {project.images.map((image: string, index: number) => (
          <ProjectImage
            key={index}
            src={image}
            alt={project.title}
            loading="lazy"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        ))}
      </div>
    </ProjectContainer>
  ) : (
    <></>
  );
};
