import React from "react";
import contactPromoVerticalLight from "../../assets/images/contact-vertical-light-min.webp";
import contactPromoVerticalDark from "../../assets/images/contact-vertical-dark-min.webp";
import contactPromoHorizontalLight from "../../assets/images/contact-horizontal-dark-min.webp";
import contactPromoHorizontalDark from "../../assets/images/contact-horizontal-dark-min.webp";
import { ContactEmailContainer, ContactLinks, ContactTitle, PageContainer } from "../../util/Styles";
import ContactForm from "../ui/ContactForm";
import { SocialLinks } from "../ui/SocialLinks";
import { Meta } from "../../config/helmet";
import { SendMailButton } from "../ui/SendMailButton";
import { useTranslation } from "react-i18next";

const Contact: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta page="contact" />
      <PageContainer className="responsive-flex" style={{ alignItems: "center", gap: 30 }}>
        <div style={{ flexBasis: "45%" }}>
          <img
            className="hide-xs"
            src={localStorage.getItem("theme") === "dark" ? contactPromoVerticalDark : contactPromoVerticalLight}
            alt={t("contact.imageAlt")}
            style={{ width: "100%" }}
          />
          <img
            className="only-xs"
            src={localStorage.getItem("theme") === "dark" ? contactPromoHorizontalDark : contactPromoHorizontalLight}
            alt={t("contact.imageAlt")}
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ flexBasis: "55%" }}>
          <ContactTitle>{t("contact.title")}</ContactTitle>
          <ContactLinks>
            <ContactEmailContainer>
              <p className="font-hairline" style={{ marginLeft: 10, fontSize: 20, fontWeight: 900 }}>
                {t("contact.sendUsEmail")}
              </p>
              <SendMailButton />
            </ContactEmailContainer>
            <SocialLinks />
          </ContactLinks>
          <hr style={{ margin: "40px 0", border: "1px solid #3d3d3d80" }} />
          <ContactForm />
        </div>
      </PageContainer>
    </>
  );
};

export default Contact;
