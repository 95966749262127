import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FAQItem, FAQItemQuestion, FAQTitle, FAQWrapper } from "../../util/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../config/config";
import faqImageTop from "../../assets/images/FAQ01-min.webp";
import faqImageBottom from "../../assets/images/FAQ02-min.webp";
import { useTranslation } from "react-i18next";

type FAQ = {
  question: string;
  answer: string;
};

const FAQComponent: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [maxHeight, setMaxHeight] = useState<string>("0px");
  const answerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const faqList: FAQ[] = [
    { question: t("faq.q1"), answer: t("faq.a1") },
    { question: t("faq.q2"), answer: t("faq.a2") },
    { question: t("faq.q3"), answer: t("faq.a3") },
    { question: t("faq.q4"), answer: t("faq.a4") },
    { question: t("faq.q5"), answer: t("faq.a5") },
    { question: t("faq.q6"), answer: t("faq.a6") },
    { question: t("faq.q7"), answer: t("faq.a7") },
    { question: t("faq.q8"), answer: t("faq.a8") },
  ];

  // Create JSON-LD schema based on the current language FAQ data
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqList.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };

  useEffect(() => {
    if (answerRef.current && openIndex !== null) {
      setMaxHeight(`${answerRef.current.scrollHeight}px`);
    } else {
      setMaxHeight("0px");
    }
  }, [openIndex]);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQWrapper>
      {/* Inject the JSON-LD schema for FAQ structured data */}
      <HelmetProvider>
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
        </Helmet>
      </HelmetProvider>

      <FAQTitle>
        <img src={faqImageTop} alt={"zeam-faq-img"} />
        <div style={{ display: "flex", flexDirection: "column", padding: "40px 0", gap: 15 }}>
          <h2 style={{ textAlign: "center", margin: 0 }}>{t("faq.title")}</h2>
          <p className="font-hairline" style={{ fontWeight: 900, textAlign: "center", margin: 0 }}>
            {t("faq.headline")}
          </p>
        </div>
        <img className="hide-xs" src={faqImageBottom} alt={"zeam-faq-img"} />
      </FAQTitle>

      <div style={{ display: "flex", flexDirection: "column", gap: 5, flexBasis: "50%" }}>
        {faqList.map((faq, index) => (
          <FAQItem key={index}>
            <FAQItemQuestion className="cursor-pointer font-hairline" onClick={() => toggleFAQ(index)}>
              {faq.question}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  transition: `${config.animation.styleAnimationDuration}ms`,
                  transform: openIndex === index ? "rotate(90deg)" : "initial",
                }}
              />
            </FAQItemQuestion>
            <div
              ref={openIndex === index ? answerRef : null}
              style={{
                maxHeight: openIndex === index ? maxHeight : "0px",
                overflow: "hidden",
                transition: "max-height 0.5s ease",
              }}
            >
              <div className="font-montserrat" style={{ marginTop: 10, lineHeight: 1.5 }}>
                {faq.answer}
              </div>
            </div>
          </FAQItem>
        ))}
      </div>

      <img className="only-xs" style={{ width: "70%" }} src={faqImageBottom} alt={"zeam-faq-img"} />
    </FAQWrapper>
  );
};

export default FAQComponent;
