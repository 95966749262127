import * as React from "react";
import zeamImage from "../../assets/images/zeam-min.webp";
import zeamHorizontalImage from "../../assets/images/zeam-horizontal-min.webp";
import { AboutContainer, Button, PageContainer, PromoContainer, TextContainer } from "../../util/Styles";
import { Meta } from "../../config/helmet";
import { Routes } from "../../util/Routes";
import { useNavigate } from "react-router-dom";
import Clients from "../ui/Clients";
import { Trans, useTranslation } from "react-i18next";
import { CreateNavUrl } from "../../util/Utils";

const About: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const BrandName = <span className="font-hairline" style={{ fontSize: 26 }} />;

  return (
    <>
      <Meta page="about" />
      <PageContainer>
        <div className="responsive-flex" style={{ gap: 30 }}>
          <div style={{ flexBasis: "45%" }}>
            <img
              className="hide-xs"
              src={zeamImage}
              alt={t("about.imageAlt")}
              style={{ width: "100%", height: "fit-content" }}
            />
            <img className="only-xs" src={zeamHorizontalImage} alt={t("about.imageAlt")} style={{ maxWidth: "100%" }} />
          </div>
          <div style={{ flexBasis: "55%" }}>
            <h1 style={{ marginBottom: 0 }}>{t("about.title")}</h1>
            <AboutContainer>
              <TextContainer
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 300,
                  lineHeight: 1.3,
                }}
              >
                <p>
                  <span className="font-hairline" style={{ fontSize: 26 }}>
                    ZEAM
                  </span>{" "}
                  {t("about.paragraph1")}
                  <br />
                  {t("about.paragraph2")}
                </p>
                <p>
                  <Trans i18nKey="about.paragraph3" values={{ brandName: "ZEAM" }} components={{ span: BrandName }} />
                </p>
                <p>
                  <Trans i18nKey="about.paragraph4" values={{ brandName: "ZE" }} components={{ span: BrandName }} />
                </p>
                <p>
                  <Trans i18nKey="about.paragraph5" values={{ brandName: "AMIN" }} components={{ span: BrandName }} />
                </p>
                <p>
                  <Trans i18nKey="about.paragraph6" values={{ brandName: "ZEAM" }} components={{ span: BrandName }} />
                </p>
                <PromoContainer style={{ marginTop: 40 }}>
                  <h2
                    className="font-hairline"
                    style={{
                      fontSize: 36,
                      marginTop: 0,
                    }}
                  >
                    {t("about.letsTalk")}
                  </h2>
                  <p>{t("about.pricingInfo")}</p>
                  <Button onClick={() => navigate(CreateNavUrl(Routes.CONTACT))} style={{ marginLeft: 10 }}>
                    {t("about.letsTalk")}
                  </Button>
                </PromoContainer>
              </TextContainer>
            </AboutContainer>
          </div>
        </div>
        <Clients />
      </PageContainer>
    </>
  );
};

export default About;
